<template>
    <!-- Associate Image Component -->
    <AssociatesImageComponent :associate-id="associateId" :image-path="associate.associateImage"
        v-model="associate.associateImage" />

    <!-- Notifications Component -->
    <Notifications ref="notification" />

    <form class="form" @submit.prevent="updateAssociate">
        <div class="form__section">
            <h3 class="form__section-title">Associate Info</h3>

            <div class="form__two-fields-form">
                <div class="input-form">
                    <label class="form__input-label form--required">
                        Full Name
                    </label>

                    <input type="text" class="form__input" placeholder="Associate Full Name" v-model="associate.name">

                    <div class="form__input-error" v-if="errors.fields.name">
                        {{ errors.fields.name[0] }}
                    </div>
                </div>

                <div class="input-form">
                    <label class="form__input-label form--required">
                        Date of Birth
                    </label>

                    <input type="date" class="form__input" placeholder="Associate Full Name" v-model="associate.dob"
                        max="2008-12-31">

                    <div class="form__input-error" v-if="errors.fields.dob">
                        {{ errors.fields.dob[0] }}
                    </div>
                </div>

                <div class="input-form">
                    <label class="form__input-label form--required">
                        Phone
                    </label>

                    <input type="text" class="form__input" placeholder="Associate Phone" v-model="associate.phone">

                    <div class="form__input-error" v-if="errors.fields.phone">
                        {{ errors.fields.phone[0] }}
                    </div>
                </div>

                <div class="input-form">
                    <label class="form__input-label form--required">
                        Address
                    </label>

                    <input type="text" class="form__input" placeholder="Associate Address" v-model="associate.address" disabled>

                    <div class="form__input-error" v-if="errors.fields.address">
                        {{ errors.fields.address[0] }}
                    </div>
                </div>
            </div>
        </div>

        <div class="form__section">
            <h3 class="form__section-title">Login Information</h3>

            <div class="form__two-fields-form">
                <div class="input-form">
                    <label class="form__input-label">
                        Email
                    </label>

                    <input type="text" class="form__input" placeholder="Associate Email" v-model="associate.email">

                    <div class="form__input-error" v-if="errors.fields.email">
                        {{ errors.fields.email[0] }}
                    </div>
                </div>

                <div class="input-form">
                    <label class="form__input-label form--required">
                        Username
                    </label>

                    <input type="text" class="form__input" placeholder="Associate Username"
                        v-model="associate.userName">

                    <div class="form__input-error" v-if="errors.fields.userName">
                        {{ errors.fields.userName[0] }}
                    </div>
                </div>

                <div class="input-form">
                    <label class="form__input-label">
                        Password
                    </label>

                    <input type="password" class="form__input" placeholder="******" v-model="associate.password">

                    <div class="form__input-error" v-if="errors.fields.password">
                        {{ errors.fields.password[0] }}
                    </div>
                </div>

                <div class="input-form">
                    <label class="form__input-label">
                        Confirm Password
                    </label>

                    <input type="password" class="form__input" placeholder="******"
                        v-model="associate.password_confirmation">

                    <div class="form__input-error" v-if="errors.fields.password_confirmation">
                        {{ errors.fields.password_confirmation[0] }}
                    </div>
                </div>

                <div class="input-form">
                    <label class="form__input-label">
                        PIN
                    </label>

                    <input type="password" class="form__input" placeholder="****" v-model="associate.pin">

                    <div class="form__input-error" v-if="errors.fields.pin">
                        {{ errors.fields.pin[0] }}
                    </div>
                </div>

                <div class="input-form">
                    <label class="form__input-label">
                        Confirm PIN
                    </label>

                    <input type="password" class="form__input" placeholder="****" v-model="associate.pin_confirmation">

                    <div class="form__input-error" v-if="errors.fields.pin_confirmation">
                        {{ errors.fields.pin_confirmation[0] }}
                    </div>
                </div>
            </div>
        </div>

        <div class="form__buttons">
            <button class="button button--success">
                <img src="@/assets/icons/vuesax/linear/refresh-2.svg" alt="">
                Update Associate
            </button>
        </div>
    </form>
</template>

<script setup>
import AssociatesImageComponent from './AssociatesImageComponent.vue'
import { associateModel } from '../Models/AssociateModel.js'
import Notifications from '@/common/Notifications.vue'
import { associatesServices } from '../Services/AssociatesServices.js'
import { ref, onMounted, reactive } from 'vue'
import { sweetAlerts } from '../../../helpers/SweetAlert.js'
import { useRouter } from 'vue-router'
import { useClientStore } from '../../../stores/ClientStore'

const { editAssociateService, updateAssociateService, errors } = associatesServices()
const notification = ref(null);
const props = defineProps(['associateId'])
const { sweetSuccess } = sweetAlerts()
const router = useRouter()
const associate = reactive({ ...associateModel() })
const { client } = useClientStore()

onMounted(() => { editAssociate() })

async function editAssociate() {
    const response = await editAssociateService(props.associateId)

    if (response.hasErrors) return console.error(`Error getting associate: ${response.message}`)

    response.data.address = client.hotelAddress

    Object.assign(associate, response.data)
}

async function updateAssociate() {
    const response = await updateAssociateService(props.associateId, associate)

    if (response.hasErrors) {
        notification.value.sendNotification('error', 'Error', response.message)
        return console.error(`Error updated associate: ${response.hasErrors}`)
    }

    sweetSuccess(response.data.message)

    return router.push('/')
}
</script>